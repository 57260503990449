import { IBidding, IGeneral } from '@pickles/shared/models';
import { STATUS } from '@pickles/shared/models/bidding.types';
import { AuctionStatus } from '@pickles/shared/models/ebid.types';
import { Account } from '@pickles/shared/models/user.types';
import moment from 'moment';
import {
  defaultBigIndustrial,
  defaultBigSalvage,
  defaultBigUsedCar,
  defaultSmallIndustrial,
  defaultSmallSalvage,
  defaultSmallUsedCar,
} from '../components/Shared/Icons';

export type BiddingStatus = 'winning' | 'outbid' | 'live' | 'lost' | 'won' | 'offer' | 'lotted';

type Bidding = {
  winnerId?: string;
  auctionStatus: AuctionStatus;
  currentBidAmt?: number;
  saleStatus?: 'OFFER';
  lastBid: number;
};

// TODO: Please use getBiddingStatus from shared/utils/helpers
export function getBiddingStatus<T extends Bidding>(item: T, user: Account): IBidding.STATUS {
  const isUserWinner = item.winnerId === user.id.toString();

  if (item.auctionStatus === 'RUNNING') {
    if (isUserWinner) {
      return IBidding.STATUS.WINNING;
    } else if (item.winnerId && item.lastBid) {
      return IBidding.STATUS.OUTBID;
    }
    return IBidding.STATUS.LIVE;
  }

  if (item.saleStatus === 'OFFER' && isUserWinner) {
    return IBidding.STATUS.OFFER;
  }

  if (!item.winnerId) {
    return IBidding.STATUS.LOTTED;
  } else if (isUserWinner) {
    return IBidding.STATUS.WON;
  }

  return STATUS.LOST;
}

export const formatDate = (date: string, format = 'MMM D, YYYY') => {
  return moment(date).format(format);
};

export const composeGallerySource = (data?: string[]) => {
  if (!data) {
    return [];
  }
  const handledArray = data.map((item) => {
    return { original: item };
  });
  return handledArray;
};

export function getDefaultImage(itemEventCategory: IGeneral.EventCategory, isBigSize = false) {
  if (isBigSize) {
    switch (itemEventCategory) {
      case IGeneral.EVENT_CATEGORY.INDUSTRIAL:
        return defaultBigIndustrial;
      case IGeneral.EVENT_CATEGORY.SALVAGE:
        return defaultBigSalvage;
      default:
        return defaultBigUsedCar;
    }
  } else {
    switch (itemEventCategory) {
      case IGeneral.EVENT_CATEGORY.INDUSTRIAL:
        return defaultSmallIndustrial;
      case IGeneral.EVENT_CATEGORY.SALVAGE:
        return defaultSmallSalvage;
      default:
        return defaultSmallUsedCar;
    }
  }
}

export const createPortalWrapper = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  const portalRoot = document.getElementById('portal-root');
  wrapperElement.setAttribute('id', wrapperId);
  portalRoot?.appendChild(wrapperElement);
  return wrapperElement;
};

export const isValidUrl = (url: string) => {
  let _url;
  try {
    _url = new URL(url);
  } catch (error) {
    return false;
  }
  return _url.protocol === 'http:' || _url.protocol === 'https:';
};
