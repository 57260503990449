import styled, { css } from 'styled-components';
import sizes from '../../../../../util/sizes';
import { SellHeaderImageWrapper } from '../../../../static/SellContent/style';
import { ExpandDown } from '../../../../../styles/animations';

export const MobileFooterWrapper = styled(SellHeaderImageWrapper)`
  flex: 1 1 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #ffffff;
  margin-bottom: 0;
  margin-left: 0;
  justify-content: flex-start;
  @media (max-width: ${sizes.M}) {
    flex-direction: column;
    padding: 0 20px;
  }
  width: 85% !important;
  > div {
    margin-bottom: 12px;
  }
`;

export const MobileFooterItems = styled.div`
  padding: 10px 0;

  > div {
    margin: 12px 0;
    cursor: pointer;
  }
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #40608b;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface IAccordionContentContainerProps {
  isOpen?: boolean;
};

export const AccordionContentContainer = styled.div<IAccordionContentContainerProps>`
  width: 100%;
  animation-name: ${({ isOpen: isOpen }) => isOpen ? css`${ExpandDown}` : 'none'};
  animation-duration: 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface IAccordionHeaderArrowWrapperProps {
  isOpen?: boolean;
};

export const AccordionHeaderArrowWrapper = styled.div<IAccordionHeaderArrowWrapperProps>`
  transform: ${({ isOpen: isOpen }) => (isOpen ? `rotate(${isOpen ? -180 : 0}deg)` : 'none')};
  transition: all 0.3s;
`;

export const NavigationHeader = styled.h3`
  opacity: 0.4;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  margin-left: 0 !important;
  text-align: start;
  text-transform: uppercase;
  color: #ffffff;
`;

export const MobileNavMenuContainer = styled.div`
  margin-top: 23px;
  margin-bottom: 13px;
  width: 100%;
  color: white;
  line-height: 21px;
  font-family: 'BasierCircle';
`;

export const MobileNavMenuItemContainer = styled.div`
  margin-top: 10px;
`;

export const MobileNavMenuItemHeaderWrapper = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const MobileNavMenuItemContentWrapper = styled.div`
  opacity: 0.80;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
