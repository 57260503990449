import React, { FC, ReactChildren, ReactElement } from 'react';
import { CSSProperties } from 'styled-components';
import { FlexWrapper, IWrapperProps } from './styles';

interface PropsType extends IWrapperProps {
  children: any;
  flexDirection?: CSSProperties['flexDirection'];
  margin?: CSSProperties['margin'];
  alignItems?: CSSProperties['alignItems'];
  gap?: CSSProperties['gap'];
  onClick?: () => void;
}

export const Flex: FC<PropsType> = ({ children, ...restProps }) => {
  return <FlexWrapper {...restProps}>{children}</FlexWrapper>;
};
