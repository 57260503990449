import styled from 'styled-components';
import sizes from '../../../util/sizes';
import { Flex } from '../Layout/Flex';

export const MobileAuthBlockWrapper = styled(Flex)<IMobileCreateArrowImg>`
  background: ${({ isBackgroundArrow }) =>
    isBackgroundArrow
      ? 'linear-gradient(107.48deg, #79ABEE -12.3%, #1C4D8E 46.24%)'
      : 'linear-gradient(107.69deg, #79ABEE -34.26%, #3765A2 46.22%), #F2F6FF;'};

  box-shadow: 0px 4px 16px rgba(25, 73, 135, 0.31);
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  display: none;
  margin: ${({ isBackgroundArrow }) => (isBackgroundArrow ? '20px' : '20px 0 0 0')};
  width: calc(100vw - 40px);
  padding-left: 15px;
  height: 98px;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${sizes.M}) {
    display: flex;
  }
`;

export const MobileCreateText = styled.div`
  color: #fff;
  white-space: nowrap;
  font-size: 13px;
  opacity: 0.6;
`;

export const MobileCreateHeader = styled(MobileCreateText)`
  opacity: 1;
  font-size: 24px;
  margin-bottom: 2px;
`;
interface IImageWrapper {
  width: number;
  height: number;
}
export const ImageWrapper = styled.div<IImageWrapper>`
  span img {
    min-width: ${({ width }) => width}px;
    min-width: ${({ height }) => height}px;
  }
`;
export const MobileAuthBlockLogo = styled.div`
  background: #1c4d8e;
  width: 32px;
  height: 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  img {
    filter: brightness(0) invert(1);
  }
`;
interface IMobileCreateArrowImg {
  isBackgroundArrow?: boolean;
}
export const MobileCreateArrowImg = styled.div<IMobileCreateArrowImg>`
  background: ${({ isBackgroundArrow }) => (isBackgroundArrow ? '#4d71a4' : 'none')};
  min-width: 47px;
  height: 40px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MobileAuthBlockContent = styled.div<IMobileCreateArrowImg>`
  > div:first-child {
    font-size: ${({ isBackgroundArrow }) => (isBackgroundArrow ? '24px' : '20px')};
  }
`;
