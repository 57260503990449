import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';
import { CSSProperties } from 'react';

interface IMainText {
  align?: string;
  fontSize?: number;
  marginBottom?: number;
  marginTop?: number;
  fontFamily?: string;
  fontColor?: string;
  lineHeight?: CSSProperties['lineHeight'];
}

export const MainText = styled.div<IMainText>`
  font-family: ${({ fontFamily = 'BasierCircle-Semibold' }) => fontFamily};
  font-size: ${({ fontSize = 38 }) => fontSize}px;
  text-align: ${({ align = 'left' }) => align};
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  color: ${({ fontColor = COLORS.rhino_black }) => fontColor};
  white-space: pre-wrap;
  word-break: break-word;
  line-height: ${({ lineHeight }) => lineHeight};
  max-width: ${sizes.XL};

  /* @media (max-width: ${sizes.S}) {
    width: calc(100% - 40px);
    white-space: nowrap;
    font-size: 32px;
  } */
`;
