import { FC, useState } from 'react';
import Link from 'next/link';
import _ from 'lodash';

import { IWeb } from '@pickles/shared/models';
import colors from '@pickles/shared/utils/colors';

import { ArrowDownIcon } from '../../../Icons';
import {
  AccordionHeaderContainer,
  AccordionHeaderArrowWrapper,
  AccordionContentContainer,
  MobileNavMenuItemHeaderWrapper,
  MobileNavMenuItemContentWrapper
} from './style';

interface IMobileNavItemProps {
  item: {
    text: string;
    link?: string;
    strapiKey?: string;
  };
  headerLink?: IWeb.ILink[];
}

const MobileNavItem: FC<IMobileNavItemProps> = ({ item, headerLink }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  };

  const mobileNavItemHeaderLinkContent = _.map(headerLink, (link) => {
    return (
      <Link
        key={`mobile-nav-${link.id}`}
        passHref={true}
        href={link.basePath ? `/${link.basePath}/${link.link}` : `/${link.link}`}
      >
        <MobileNavMenuItemContentWrapper>
          {link.title}
        </MobileNavMenuItemContentWrapper>
      </Link>
    );
  });

  return (
    <>
      <AccordionHeaderContainer onClick={toggleAccordion}>
        <MobileNavMenuItemHeaderWrapper>{item.text}</MobileNavMenuItemHeaderWrapper>
        <AccordionHeaderArrowWrapper isOpen={isOpen}>
          <ArrowDownIcon
            width={20}
            height={20}
            strokeColor={colors.white}
          />
        </AccordionHeaderArrowWrapper>
      </AccordionHeaderContainer>
      {isOpen && <AccordionContentContainer isOpen={isOpen}>
        {item?.link && <Link
          passHref={true}
          href={item.link}
        >
          <MobileNavMenuItemContentWrapper>
            {item.text}
          </MobileNavMenuItemContentWrapper>
        </Link>}
        {mobileNavItemHeaderLinkContent}
      </AccordionContentContainer>}
    </>
  );
};

export default MobileNavItem;