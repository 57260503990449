import { FC } from 'react';
import _ from 'lodash';

import { IWeb } from '@pickles/shared/models';

import MobileNavItem from './MobileNavItem';

import {
  NavigationHeader,
  MobileNavMenuContainer,
  MobileNavMenuItemContainer,
} from './style';

interface IMobileNavMenuProps {
  navBarOptions: {
    text: string;
    link?: string;
    strapiKey?: string;
  }[];
  headerLinks?: { [key: string]: IWeb.ILink[] };
};

const MobileNavMenu: FC<IMobileNavMenuProps> = ({ navBarOptions, headerLinks }) => {

  const mobileNavMenu = _.map(navBarOptions, (item, index) => {
    const headerLink = _.get(headerLinks, `${item.strapiKey}`)

    return (
      <MobileNavItem
        key={`${item.text}-${index}`}
        item={item}
        headerLink={headerLink}
      />
    );
  });

  return (
    <MobileNavMenuContainer>
      <NavigationHeader>Navigation</NavigationHeader>
      <MobileNavMenuItemContainer>{mobileNavMenu}</MobileNavMenuItemContainer>
    </MobileNavMenuContainer>
  );
};

export default MobileNavMenu;