import { keyframes } from 'styled-components';

export const SlidingUp = keyframes`
  0% { opacity: 0; transform: translateY(-30%); }
  100% { opacity: 1; transform: translateY(0);}
`;

export const SlidingRight = keyframes`
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0%)
  }
`;

export const smooth = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2
  }
  65%{
    transform: translateY(-60px);
  }
  74%{
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(-50px);
    opacity: 1;
  }
`;

export const ExpandDown = keyframes`
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: max-content;
  }
`;