import { FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { DashboardHooks, EbidAuctionHooks, UserHooks } from '../../../../app/redux/hooks';
import { profileItems, ProfileMenuTitles } from '../../../Navbar/ProfileMenu';
import {
  ProfileMenuIcon,
  ProfileMenuItem,
  ProfileMenuItemText,
  ProfileMenuItemUnits,
} from '../../../Navbar/ProfileMenu.style';
import { MobileAuthBlock } from '../../MobileAuthBlock';
import { MobileProfileHeaderInfo } from '../../MobileProfileHeaderInfo';
import { MobileFooter } from './components/MobileFooter';
import { IWeb } from '@pickles/shared/models';

import {
  MobileMenuWrapper,
  MobileProfileMenuBody,
  MobileProfileMenuItems,
  MobileNavWrapper,
} from './style';
import { head } from 'lodash';
import MobileNavMenu from './components/MobileNavMenu';
import { MobileFooterWrapper } from './components/style';

const options = [
  { img: '/images/countries/usaFlag.png', title: 'English' },
  { img: '/images/countries/malaysiaFlag.png', title: 'Bahasa Malaysia' },
  { img: '/images/countries/chinaFlag.png', title: '简体' },
];

const footerInfo = [
  { title: 'Add-on Services', link: '/services' },
  { title: 'About Us', link: '/about-us' },
  { title: 'FAQ', link: '/FAQ' },
  { title: 'Help & Support', link: '/contact-us' },
  { title: 'Terms and Conditions', link: '/auction-terms' },
];

interface IMobileMenuProps {
  navBarOptions: {
    text: string;
    link?: string;
    strapiKey?: string;
  }[];
  headerLinks?: { [key: string]: IWeb.ILink[] };
};

export const MobileMenu: FC<IMobileMenuProps> = ({ navBarOptions, headerLinks }) => {
  const router = useRouter();
  const account = UserHooks.useUserAccount();
  const runningAuctions = DashboardHooks.useRunningAuctions();
  const countsObj = DashboardHooks.useCounts();
  const { winning: ebidWinning, outbid: ebidOutbid } = EbidAuctionHooks.useCounts();

  const profileAssets = {
    [ProfileMenuTitles.Auctions]: runningAuctions?.length,
    [ProfileMenuTitles.MyPrebids]: `${countsObj?.prebidsCt}`,
    [ProfileMenuTitles.MyEBids]: [ebidWinning, ebidOutbid],
    [ProfileMenuTitles.Purchases]: `${countsObj?.offers}`,
    [ProfileMenuTitles.MyWatchlist]: `${countsObj?.favsCt}`,
  };

  const handleItemPress = (route: string) => {
    router.push(`/${route}`);
  };

  const handleProfilePress = () => {
    router.push('/profile');
  };

  const renderProfileMenuUnits = (
    backColorUnits: string[],
    title: ProfileMenuTitles,
    testKey: string,
  ) => {
    return backColorUnits?.map((backColor, index) => {
      const count = profileAssets[title as keyof typeof profileAssets];

      return (
        <ProfileMenuItemUnits
          id={`${testKey}_${index}_count`}
          key={`profile-units-${index}`}
          backColorUnits={backColor}
        >
          {count[index]}
        </ProfileMenuItemUnits>
      );
    });
  };

  const profileMenuItems = profileItems.map((item) => {
    return (
      <ProfileMenuItem
        key={`profile-menu-item-${item.text}`}
        onClick={() => handleItemPress(item.route)}
      >
        {item.icon && (
          <ProfileMenuIcon>
            <Image src={item.icon} alt={item.text} />
          </ProfileMenuIcon>
        )}
        <ProfileMenuItemText isMobile>
          {item.text}
          {account ? (
            <div>
              {Array.isArray(item.backColorUnits) ? (
                renderProfileMenuUnits(item.backColorUnits, item.titleId, item.testKey)
              ) : (
                <ProfileMenuItemUnits
                  id={`${item.testKey}_count`}
                  backColorUnits={item.backColorUnits}
                >
                  {profileAssets[item.titleId]}
                  {item?.unitName}
                </ProfileMenuItemUnits>
              )}
            </div>
          ) : null}
        </ProfileMenuItemText>
      </ProfileMenuItem>
    );
  });

  return (
    <MobileMenuWrapper id="mobile-menu-wrapper">
      {/* TODO: Hiding language selection during first phase of web release, to bring back later */}
      <div style={{ marginBottom: 20 }} />
      {/* <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <MobileLangSwitcher>
          <SelectImage type={FilterTypes.Image} options={options} selectedIndex={0} />
        </MobileLangSwitcher>
      </Flex> */}

      {account ? (
        <>
          <MobileProfileHeaderInfo account={account} handleProfilePress={handleProfilePress} />
          <MobileProfileMenuBody>
            <MobileProfileMenuItems>{profileMenuItems}</MobileProfileMenuItems>
          </MobileProfileMenuBody>
          <MobileFooterWrapper>
            <MobileNavMenu navBarOptions={navBarOptions} headerLinks={headerLinks} />
          </MobileFooterWrapper>
        </>
      ) : (
        <>
          <MobileAuthBlock isBackgroundArrow={false} />
          <MobileNavWrapper>
            <MobileNavMenu navBarOptions={navBarOptions} headerLinks={headerLinks} />
          </MobileNavWrapper>
        </>
      )}
    </MobileMenuWrapper>
  );
};
