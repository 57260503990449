import sizes from './../../util/sizes';
import styled from 'styled-components';
interface IContainer {
  isMobileHidden?: boolean;
}
export const Container = styled.div<IContainer>`
  @media (max-width: ${sizes.M}) {
    padding: 0;
    // padding-bottom: 0;
    display: ${({ isMobileHidden }) => (!isMobileHidden ? 'block' : 'none')};
  }
`;

export const ContentContainer = styled.div`
  min-height: calc(100vh - 356px - 85px);
`;
