import React, { useLayoutEffect, useState } from 'react';
import sizes from '../util/sizes';
import useWindowWidth from '../util/useWindowWidth';

const useScreenSize = () => {
  const [screenSize, setscreenSize] = useState<'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'>();
  const [minWidth, setMinWidth] = useState({
    xs: false,
    s: false,
    m: false,
    l: false,
    xl: false,
    xxl: false,
  });
  const [maxWidth, setMaxWidth] = useState({
    xs: false,
    s: false,
    m: false,
    l: false,
    xl: false,
    xxl: false,
  });

  const resized = useWindowWidth();

  useLayoutEffect(() => {
    const mediaXS = window.matchMedia(`(max-width: ${sizes.XS})`).matches;
    const mediaS = window.matchMedia(`(max-width: ${sizes.S})`).matches;
    const mediaM = window.matchMedia(`(max-width: ${sizes.M})`).matches;
    const mediaL = window.matchMedia(`(max-width: ${sizes.L})`).matches;
    const mediaXL = window.matchMedia(`(max-width: ${sizes.XL})`).matches;
    const mediaXXL = window.matchMedia(`(max-width: ${sizes.XXL})`).matches;

    setMinWidth({
      xs: !mediaXS,
      s: !mediaS,
      m: !mediaM,
      l: !mediaL,
      xl: !mediaXL,
      xxl: !mediaXXL,
    });

    setMaxWidth({
      xs: mediaXS,
      s: mediaS,
      m: mediaM,
      l: mediaL,
      xl: mediaXL,
      xxl: mediaXXL,
    });

    if (mediaXS) {
      setscreenSize('XS');
    } else if (mediaS) {
      setscreenSize('S');
    } else if (mediaM) {
      setscreenSize('M');
    } else if (mediaL) {
      setscreenSize('L');
    } else if (mediaXL) {
      setscreenSize('XL');
    } else {
      setscreenSize('XXL');
    }
  }, [resized]);

  return {
    screenSize,
    minWidth,
    maxWidth,
    isMobile: maxWidth.s,
    isTablet: maxWidth.m,
    isDesktop: maxWidth.l,
  };
};

export default useScreenSize;
