import sizes from './../../../util/sizes';
import styled from 'styled-components';

export const BurgerWrapper = styled.div<IBurgerProps>`
  height: 18px;
  font-size: 16px;
  font-family: BasierCircle-Medium;
  display: none;

  div {
    text-align: start;
  }

  input {
    display: none;
  }
  label {
    position: relative;
    display: flex;
    height: 18px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.2s ease-in;
    /* margin-right: 20px; */
    align-items: flex-start;
    position: ${({ isChecked }) => (isChecked ? 'fixed' : 'relative')};
    top: ${({ isChecked }) => (isChecked ? '25px' : 'auto')};
    right: ${({ isChecked }) => (isChecked ? '20px' : 'auto')};
    span {
      color: ${({ isChecked }) => (isChecked ? '#fff' : '#1c4d8e')};
      z-index: 6;
      margin-top: 2px;
      margin-left: 75px;
    }
  }
  @media (max-width: ${sizes.M}) {
    display: flex;
    align-items: center;
  }
`;
interface IBurgerProps {
  isChecked?: boolean;
}
export const BurgerLines = styled.div<IBurgerProps>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  margin-right: 7px;

  span:first-child {
    transform: translate(-50%, -15px) rotate(0deg);
  }

  span:last-child {
    transform: translate(-50%, 10px) rotate(0deg);
  }
`;

export const BurgerMenuItems = styled.div<IBurgerProps>`
  position: fixed;
  top: 0;
  right: ${({ isChecked }) => (isChecked ? '-15%' : '-100%')};
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  padding-top: 20px;
  > div {
    > div:not(:last-child),
    h3 {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(85% - 40px);
    }
    > div:last-child {
      width: 100%;
      // margin-top: 30px;
    }
  }
  background: linear-gradient(180deg, #1c4d8e 0%, #122e52 100%);
  text-align: center;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 75%);
  transition: all 0.5s ease-in-out;
  z-index: 5;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > div {
      color: #fff;
      > div {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
    }
  }
`;

export const BurgerLine = styled.div<IBurgerProps>`
  width: 18px;
  height: 2px;
  border-radius: 3px;
  transform-origin: left center;
  transition: all 0.5s;
  background-color: ${({ isChecked }) => (isChecked ? '#fff' : ' #1c4d8e')};

  &:first-child {
    transform: translate(-50%, -7px) rotate(0deg);
  }

  &:last-child {
    transform: translate(-50%, 5px) rotate(0deg);
  }

  ${({ isChecked }) =>
    isChecked &&
    `
    &:nth-child(2) {
      opacity: 0;
    }
    
    &:first-child {
      transform: translate(0, -5px) rotate(45deg);
    }

    &:last-child {
      transform: translate(0, 8px) rotate(-45deg);
    }
  `}

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
  opacity: 1;
`;
