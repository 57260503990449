import { useEffect, useState } from 'react';

const useWindowWidth = () => {
  const [resized, setResized] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout | null;

    const handleResize = () => {
      timer = setTimeout(() => {
        setResized((state) => state + 1);
      }, 250);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return resized;
};

export default useWindowWidth;
