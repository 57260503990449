import styled, { css, CSSProperties } from 'styled-components';

export const ClickButton = styled.button`
  position: relative;
  cursor: pointer;
  transition-duration: 0.4s;
  user-select: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    transition-duration: 0.1s;
    background-color: #263a54;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    border-radius: 8px;
    box-shadow: 0 0 10px 20px blue;
  }

  &:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
    border-radius: 8px;
  }
`;

interface BoxProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

export const Box = styled.div<BoxProps>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.height &&
    css`
      width: ${props.height};
    `};
`;
