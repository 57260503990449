import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Layout/Flex';
import {
  MobileAuthBlockLogo,
  MobileAuthBlockWrapper,
  MobileCreateArrowImg,
  MobileCreateHeader,
  MobileCreateText,
  MobileAuthBlockContent,
} from './style';

import profileImg from '/public/icons/accountLogo.svg';
import arrowRightIcon from '/public/icons/arrowRight.svg';

type PropsType = {
  isBackgroundArrow?: boolean;
};

export const MobileAuthBlock: FC<PropsType> = ({ isBackgroundArrow = true }) => {
  const { t } = useTranslation();

  return (
    <Link href="/login" passHref>
      <MobileAuthBlockWrapper isBackgroundArrow={isBackgroundArrow}>
        <Flex flexDirection="row" gap="13px" alignItems="center">
          <MobileAuthBlockLogo>
            <Image src={profileImg} alt="Profile Image" />
          </MobileAuthBlockLogo>
          <MobileAuthBlockContent isBackgroundArrow={isBackgroundArrow}>
            <MobileCreateHeader>{t('titles:connect_acc')}</MobileCreateHeader>
            <MobileCreateText>{t('labels:deals_money')}</MobileCreateText>
          </MobileAuthBlockContent>
        </Flex>

        <MobileCreateArrowImg isBackgroundArrow={isBackgroundArrow}>
          <a>
            <Image src={arrowRightIcon} alt="Arrow icon" />
          </a>
        </MobileCreateArrowImg>
      </MobileAuthBlockWrapper>
    </Link>
  );
};
