import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { AccountImg, AccountText, LoginRegisterWrapper } from './style';

import profileImg from '/public/images/userIcon.png';

const LoginRegister = () => {
  const router = useRouter();

  const loginClickHandler = useCallback(() => {
    router.push('/login');
  }, []);

  return (
    <LoginRegisterWrapper onClick={loginClickHandler}>
      <AccountImg src={profileImg} />
      <AccountText>Login/Register</AccountText>
    </LoginRegisterWrapper>
  );
};

export default LoginRegister;
