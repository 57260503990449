import styled, { CSSProperties } from 'styled-components';
import sizes from '../../../../util/sizes';

export interface IWrapperProps {
  width?: string;
  marginTop?: number;
  flexDirection?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  margin?: CSSProperties['margin'];
  gap?: CSSProperties['gap'];
}

export const FlexWrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'stretch' }) => alignItems};
  width: ${({ width = '100%' }) => width};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  @media (max-width: ${sizes.S}) {
    margin: 0;
  }
`;
