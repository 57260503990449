import styled from 'styled-components';

import COLORS from '@pickles/shared/utils/colors';
import { SlidingUp } from '../../styles/animations';

interface ProfileMenuContainerProps {
  isOpened?: boolean;
}

export const ProfileMenuContainer = styled.div<ProfileMenuContainerProps>`
  position: absolute;
  font-family: BasierCircle;
  align-items: center;
  width: 240px;
  font-size: 15px;
  line-height: 20px;
  margin: 0 auto;
  border: 1px solid ${COLORS.mischka};
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  top: 68px;
  right: 40px;
  white-space: nowrap;
  opacity: 0;
  animation: ${({ isOpened }) => (isOpened ? SlidingUp : 'none')};
  display: ${({ isOpened }) => (isOpened ? 'block' : 'none')};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  background-color: ${COLORS.white};
  z-index: 100;
`;

export const ProfileMenuHead = styled.div`
  background: linear-gradient(107.69deg, ${COLORS.jordy_blue} -34.26%, ${COLORS.azure} 46.22%),
    ${COLORS.zircon_light_blue};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 14px 20px;
  > div {
    margin: 3px 0;
  }
`;

export const ProfileMenuBody = styled.div`
  margin-bottom: 15px;
  padding: 0 20px;
`;

export const ProfileMenuLine = styled.div`
  margin-top: 15px;
  opacity: 0.4;
  border-top: 2px solid ${COLORS.mischka};
`;

export const ProfileMenuFooter = styled.div`
  padding: 20px;
  padding-top: 0;
  > div {
    padding: 6px 0 3px;
  }
`;

export const ProfileMenuItems = styled.div`
  margin: 22px 0px;
`;

export const ProfileMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  margin: 10px 0px;
`;

export const ProfileUserName = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: ${COLORS.white};
`;

export const accountAuctionsBack = '#08AADE';
export const accountPrebidsBack = '#1C4D8E';
export const accountEBidsBack1 = '#27A607';
export const accountEBidsBack2 = '#DF2955';
export const accountPurchasesBack = '#ED7934';

type ProfileMenuItemUnitsType = {
  backColorUnits: string;
  isMobile?: boolean;
};

export const ProfileMenuItemUnits = styled.span<ProfileMenuItemUnitsType>`
  padding: 0 4px;
  border-radius: 4px;
  color: ${COLORS.white};
  background-color: ${({ backColorUnits }) => backColorUnits};

  font-size: 12px;
  &:nth-child(2) {
    margin-left: 6px;
    margin-right: 3px;
  }
`;
interface IProfileMenuItemText {
  isMobile?: boolean;
}
export const ProfileMenuItemText = styled.div<IProfileMenuItemText>`
  border-bottom: ${({ isMobile }) => (isMobile ? '1px solid #40608b' : 'none')};
  padding-bottom: 10px;
  width: 100%;
  text-align: start;
  display: flex;
  gap: 20px;
`;

export const ProfileMenuIcon = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

export const ProfileMenuArrow = styled.div`
  position: absolute;
  left: 85%;
  top: 10%;
  width: 15px;
  height: 2px;
  background-color: ${COLORS.white};
  border-radius: 3px;
  opacity: 0.5;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -3.5px;
    border: solid ${COLORS.white};
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(-45deg);
    border-radius: 3px;
  }
`;

export const AccountAuctions = styled(ProfileMenuItemUnits)`
  background-color: ${accountAuctionsBack};
`;

export const AccountPrebids = styled(ProfileMenuItem)`
  background-color: ${accountPrebidsBack};
`;

export const AccountEBids = styled(ProfileMenuItem)`
  > span:first-child {
    background-color: ${accountEBidsBack1};
  }
  > span:nth-child(2) {
    background-color: ${accountEBidsBack2};
  }
`;

export const AccountPurchases = styled(ProfileMenuItem)`
  background-color: ${accountPurchasesBack};
`;

export const AvailableContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  font-size: 13px;
  opacity: 1;
  > div {
    font-size: 12px;
    opacity: 0.7;
    margin-right: 5px;
  }
`;

export const BlockedContainer = styled(AvailableContainer)``;
