import React, { useEffect, useState, useRef, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import profileImg from '/public/images/userIcon.png';
import { AccountContainer, Triangle, AccountImg, AccountText } from './style';
import { ProfileMenu } from './ProfileMenu';
// import profileImg from '/public/images/userIcon.png';
import { Account } from '../../../shared/models/user.types';

const ItemIcon = styled.img`
  margin-right: 10px;
`;

interface IItemProps {
  user: Account;
  isEmpty?: boolean;
}

export const MyAccount = ({ user, isEmpty = true }: IItemProps) => {
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  const onClick = (e: any) => {
    !!menuRef.current && !menuRef.current.contains(e.target) && setIsOpened(true);
  };

  useEffect(() => {
    const closeProfileMenu = (e: any) => {
      if (isOpened && !menuRef.current?.contains(e.target)) {
        setIsOpened(false);
      }
    };

    if (isOpened) {
      document.addEventListener('click', closeProfileMenu);
    }
    return () => {
      document.removeEventListener('click', closeProfileMenu);
    };
  }, [isOpened]);

  return (
    <AccountContainer onClick={onClick}>
      <AccountImg src={profileImg} />
      <AccountText>{t('titles:my_account_web')}</AccountText>
      <Triangle />
      <ProfileMenu isOpened={isOpened} ref={menuRef} user={user} />
    </AccountContainer>
  );
};
