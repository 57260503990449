import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { appActions, webActions } from '@pickles/shared/redux/actions';
import { IWeb } from '@pickles/shared/models';

import { LoaderHooks } from '../../../app/redux/hooks';

import LoadingPortal from '../../Portal/LoadingPortal';

import { FieldError } from '../Input/style';
import {
  CompanyBlock,
  Container,
  EmailBlock,
  EmailInput,
  Icon,
  IconBlock,
  IconsPanel,
  Info,
  InfoBlock,
  LeftBlock,
  LinkItem,
  Logo,
  LogoBlock,
  LongText,
  Main,
  StayUpToDateBlock,
  SubmitButton,
  Title,
} from './styles';

const SubscriptionSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
});

interface FooterProps {
  data: any;
}

export const Footer: FC<FooterProps> = ({ data }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = LoaderHooks.useLoadingIndicator('submittingMailerSubscribeNewsletter');

  const {
    values: { email },
    errors,
    handleChange,
    handleSubmit,
  } = useFormik<IWeb.SubscribeForm>({
    initialValues: {
      type: 'SUBSCRIBE_NEWSLETTER',
      email: '',
    },
    validationSchema: SubscriptionSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!executeRecaptcha) {
        dispatch(
          appActions.showAlert({
            alertType: 'dropdown',
            title: t('titles:failure'),
            message: t('infos:message_sent_error'),
            messageType: 'error',
          }),
        );
        return;
      }
      if (!isLoading) {
        const token = await executeRecaptcha('subscription');
        dispatch(webActions.submitMailerSubscribeNewsletter(values, token));
      }
    },
  });

  if (!data?.leftBlock) {
    return <Container />;
  }

  const logoUrl = data.leftBlock?.logo.data.attributes.url;

  return (
    <>
      <Container>
        <Main>
          <LeftBlock>
            <LogoBlock>
              <Logo src={logoUrl} layout="fill" />
            </LogoBlock>
            <InfoBlock>
              <Info>{data.leftBlock?.copyright}</Info>
              <Info>{data.leftBlock?.poweredBy}</Info>
            </InfoBlock>
            <IconsPanel>
              {data.leftBlock?.social_links.data.map((item: any, index: number) => {
                // const iconUrl = STRAPI_URL + item.attributes.icon.data.attributes.url;
                const iconUrl = item.attributes.icon.data?.attributes.url;
                return (
                  <IconBlock
                    key={index}
                    onClick={() => window.open(`${item?.attributes?.url}`, '_blank')}
                  >
                    {iconUrl && <Icon src={iconUrl} width="100%" height="100%" />}
                  </IconBlock>
                );
              })}
            </IconsPanel>
          </LeftBlock>

          {data.linksBlock?.map((item: any, index: number) => {
            return (
              <CompanyBlock key={`${index}`}>
                <Title style={{ marginBottom: 24 }}>{item.title}</Title>
                {item.links.data.map((linkItem: any, linkIndex: number) => {
                  return (
                    <LinkItem
                      key={`${linkIndex}`}
                      onClick={() => window.open(`${linkItem?.attributes?.url}`, '_blank')}
                    >
                      {linkItem.attributes.label}
                    </LinkItem>
                  );
                })}
              </CompanyBlock>
            );
          })}

          <StayUpToDateBlock>
            <Title style={{ marginBottom: 12 }}>{data.rightBlock?.title}</Title>
            <LongText>{data.rightBlock?.text}</LongText>
            <EmailBlock>
              <EmailInput
                name="email"
                autoComplete="off"
                placeholder="Your email address"
                id="email"
                value={email}
                onChange={handleChange}
                borderRadius={8}
                paddingLeft={16}
              />
              <SubmitButton type="button" disabled={isLoading} onClick={() => handleSubmit()}>
                {t('buttons:submit')}
              </SubmitButton>
            </EmailBlock>
            {errors && <FieldError>{errors.email}</FieldError>}
          </StayUpToDateBlock>
        </Main>
      </Container>
      {isLoading && <LoadingPortal text="Subscribing" />}
    </>
  );
};
