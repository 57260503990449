import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';

interface ICarTypeItem {
  isSelected: boolean;
}

export const SearchContentWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 114px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${sizes.XL};

  @media (max-width: ${sizes.XL}) {
    margin-left: 60px;
    margin-right: 60px;
  }

  @media (max-width: ${sizes.M}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const SearchCarNameText = styled.div`
  font-family: BasierCircle-SemiBold;
  font-size: 36px;
  margin-bottom: 16px;
  color: ${colors.rhino_black};
`;

export const CarTypeItem = styled.div<ICarTypeItem>`
  height: 32px;
  padding: 0 10px;
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.san_juan_grey)};
  /* color: #334d70; */
  background-color: ${({ isSelected }) =>
    isSelected ? colors.blumine_main_blue : colors.zircon_light_blue};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 5px;
  cursor: pointer;
  @media (max-width: ${sizes.S}) {
    white-space: nowrap;
  }
`;
export const ClearItem = styled.div`
  height: 32px;
  color: ${colors.new_gray};
  display: flex;
  align-items: center;
  font-family: BasierCircle-Semibold;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 5px;
  padding-top: 5px;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 0.5px solid #d6dadf;
  margin-bottom: 40px;
  @media (max-width: ${sizes.S}) {
    margin-bottom: 10px;
  }
`;

export const ResultLineWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ResultCountWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${sizes.S}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ResultText = styled.div`
  font-family: BasierCircle-Semibold;
  font-size: 19px;
  color: #263a54;
  margin-right: 24px;
`;

export const SelectWrapper = styled.div`
  padding: 13px 20px 13px 14px;
  border: 1px solid #d6dadf;
  border-radius: 12px;
  font-family: BasierCircle-Medium;
  font-size: 15px;
  color: #334d70;
  margin-right: 8px;
  cursor: pointer;
`;

export const AuctionDetailItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;
