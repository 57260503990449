import React, { FC } from 'react';

import { Container, ContentContainer } from './styles';
import Navbar from '../Navbar';
import { Footer } from '../Shared/Footer';
import { AppHooks } from '../../app/redux/hooks';
import Banner from '../Banner';

interface ILayoutProps {
  children?: React.ReactElement | React.ReactElement[];
  footerTitle?: string;
  footerButton?: string;
  footerData?: any;
  footerBgColor?: string;
  subscribePopup?: boolean;
  title?: string;
  documentTitle?: string;
  documentDescription?: string;
  canonical?: string;
  activeNavIndex?: number;
  isMobileHidden?: boolean;
}

export const Layout: FC<ILayoutProps> = ({
  activeNavIndex,
  isMobileHidden,
  footerData,
  title,
  children,
}) => {
  const appConfig = AppHooks.useMobileAppConfig();

  const renderNotice = () => {
    // Only show banner in home page
    return title === 'Home' && appConfig?.dashboardNotice && appConfig?.dashboardNotice.length > 0;
  };

  return (
    <Container id="container" isMobileHidden={isMobileHidden}>
      {renderNotice() && <Banner />}
      <Navbar initialActiveNavIndex={activeNavIndex ?? -1} title={title} />
      <ContentContainer>{children}</ContentContainer>
      <Footer data={footerData} />
    </Container>
  );
};
