import colors from '@pickles/shared/utils/colors';
import styled, { css, CSSProperties } from 'styled-components';
import sizes from '../../../util/sizes';

interface TypographyProps {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'button' | 'body1' | 'body2' | 'caption';
  color?: keyof typeof colors;
  algin?: CSSProperties['textAlign'];
}

const Typography = styled.p<TypographyProps>`
  ${(props) => {
    switch (props.variant) {
      case 'h1':
        return css`
          font-weight: 600;
          font-size: 56px;
          line-height: 110%;
        `;
      case 'h2':
        return css`
          font-weight: 600;
          font-size: 36px;
          line-height: 130%;
        `;
      case 'h3':
        return css`
          font-weight: 600;
          font-size: 28px;
          line-height: 150%;
        `;
      case 'h4':
        return css`
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
        `;
      case 'h5':
        return css`
          font-weight: 400;
          font-size: 22px;
          line-height: 140%;
          letter-spacing: -0.01em;
        `;
      case 'h6':
        return css`
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
        `;
      case 'caption':
        return css`
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
        `;
      case 'button':
        return css`
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        `;
      case 'body1':
        return css`
          font-weight: 400;
          font-size: 17px;
          line-height: 150%;
          letter-spacing: -0.01em;
        `;
      case 'body2':
        return css`
          font-weight: 500;
          font-size: 17px;
          line-height: 140%;
        `;
      default:
        return css`
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
        `;
    }
  }}

  color: ${(props) => (props.color ? colors[props.color] : colors.rhino_black)};
  text-align:  ${(props) => props.algin || 'left'};
  max-width: ${sizes.XL};
`;

export default Typography;
