import React, { FC } from 'react';
import styled from 'styled-components';
import Portal from '.';
import { SlidingUp } from '../../styles/animations';
import Loading from '../Shared/Loading';
import Typography from '../Shared/Typography';

interface LoadingPortalProps {
  text?: string;
}

const LoadingPortal: FC<LoadingPortalProps> = ({ text }) => {
  return (
    <Portal wrapperId="loading-wrapper-portal">
      <LoadingModal>
        <LoadingModalDialog>
          <Loading />
          <Typography variant="body2">{text || 'Loading'}</Typography>
        </LoadingModalDialog>
      </LoadingModal>
    </Portal>
  );
};

export default LoadingPortal;

const LoadingModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  background: rgba(49, 54, 62, 0.6);
  z-index: 100;
`;

const LoadingModalDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  position: sticky;
  width: fit-content;
  overflow: auto;
  margin: 0 auto;
  margin-top: calc(100vh - 50%);
  border: none;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  animation: ${SlidingUp};
  animation-duration: 0.3s;
  ::-webkit-scrollbar {
    display: none;
  }
`;
