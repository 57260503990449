import React, { FC, useState } from 'react';
import { Flex } from '../Layout/Flex';
import { MobileMenu } from './MobileMenu';
import { BurgerLine, BurgerWrapper, BurgerLines, BurgerMenuItems } from './style';
import { IWeb } from '@pickles/shared/models';

type PropsType = { 
  children?: React.ReactChild | React.ReactChildren | null;
  navBarOptions: {
    text: string;
    link?: string;
    strapiKey?: string;
  }[];
  headerLinks?: { [key: string]: IWeb.ILink[] };
};

export const BurgerMenu: FC<PropsType> = ({ children, navBarOptions, headerLinks }) => {
  const [isChecked, setIsChecked] = useState(false);
  const onChange = () => {
    setIsChecked(!isChecked);
  };
  const lines = Array(3)
    .fill(1)
    .map((item, index) => <BurgerLine isChecked={isChecked} key={`burger-line-${index}`} />);
    
  return (
    <BurgerWrapper isChecked={isChecked}>
      <input type="checkbox" id="burger-checkbox" onChange={onChange} />
      <label htmlFor="burger-checkbox">
        <BurgerLines isChecked={isChecked}>{lines}</BurgerLines>
        <span>{isChecked ? 'Close' : 'Menu'}</span>
      </label>
      <BurgerMenuItems isChecked={isChecked}>
        <BurgerMenuItems isChecked={isChecked}>
          <MobileMenu navBarOptions={navBarOptions} headerLinks={headerLinks} />
        </BurgerMenuItems>
      </BurgerMenuItems>
    </BurgerWrapper>
  );
};
