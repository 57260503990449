import React, { FC, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { createPortalWrapper } from '../../util/helpers';

interface PortalProps {
  children: React.ReactNode;
  wrapperId: string;
}

const Portal: FC<PortalProps> = ({ children, wrapperId = 'portal-wrapper' }) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);

    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createPortalWrapper(wrapperId);
    }

    setWrapperElement(element);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export default Portal;
