import styled from 'styled-components';
import { ProfileMenuBody, ProfileMenuItems } from '../../../Navbar/ProfileMenu.style';
import { DetailsUserName } from '../../../ProfileHeader/style';
import { Flex } from '../../Layout/Flex';

export const MobileMenuWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  label {
    span {
      margin-left: 75px;
      margin-top: 3px;
    }
  }
`;

export const MobileLangSwitcher = styled.div`
  > div {
    width: fit-content;
    > div:first-child {
      padding: 5px;
      > div:last-child {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        margin-left: 7px;
        margin-right: 3px;
      }
    }
    img {
      min-width: 21px !important;
      min-height: 18px !important;
      border-radius: 2px;
    }
  }
`;

export const MobileProfileMenuBody = styled(ProfileMenuBody)`
  padding: 0;
  color: #fff;
  img {
    filter: brightness(0) invert(1);
  };
  margin-top: 10px;
`;

export const MobileProfileInfoWrapper = styled(Flex)`
  flex-direction: row;
  gap: 30px;
  padding: 0;
  margin-top: 20px;
  color: #fff;
  background: linear-gradient(107.69deg, #79abee -34.26%, #3765a2 46.22%), #f2f6ff;
  border-radius: 16px;
  padding: 14px;
  width: calc(100% - 68px);
  margin-top: 20px;
  cursor: pointer;
`;

export const MobileDetailsUserName = styled(DetailsUserName)`
  color: #fff;
`;

export const MobileProfileMenuItems = styled(ProfileMenuItems)`
  margin-top: 13px;
`;

export const MobileNavWrapper = styled.div`
  width: calc(85% - 40px) !important;
  margin-left: 20px;
`;