import colors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import sizes from '../../../util/sizes';

interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
}

interface ISellContentWrapper {
  isImage?: boolean;
}

export const SellHeaderImageWrapper = styled.div<ISellContentWrapper>`
  background: linear-gradient(226.83deg, #1c4d8e 25.8%, #122e52 123.58%);
  border-radius: 24px;
  padding: 80px 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 100%;
  padding-right: 48px;
  margin: 0 87px 84px;
  // div{
  //   white-space:${({ isImage }) => (isImage ? 'auto' : 'nowrap')};
  // }
  height: ${({ isImage }) => (isImage ? '500px' : 'auto')};
   &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: url('/icons/circles.svg') no-repeat;
    background-size: cover;
    opacity: 0.5;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: inline-block;
    position: absolute;
    top: 0;
  }
  @media (max-width: ${sizes.XL}) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media (max-width: ${sizes.L}) {
    margin-left: 40px;
    margin-right: 40px;
    padding: 8% 0;
    padding-right: 48px;
  }
  @media (max-width: ${sizes.M}) {
    flex-direction: column;
    height: auto;
    margin: 0 20px 84px;
    padding: 0 20px;
    &:after {
      height: 0;
      width: 0;
    }
  }
  @media (max-width: ${sizes.S}){
    margin: 0 0px 84px;
    border-radius: 0;
  }
`;

interface ISellHeaderImage {
  // image: string | StaticImageData;
  imageHeight?: number;
}
export const SellHeaderImage = styled.div<ISellHeaderImage>`
  height: 470px;
  border-radius: 24px;
  max-width: ${sizes.XL};
  flex: 4;
  background-size: cover;
  width: 100%;
  margin-bottom: 0px;
  display: block;

  @media (max-width: ${sizes.L}) {
    // min-width: 60%;
    max-width: 45%;
    margin: 3em 0;
  }
  @media (max-width: ${sizes.M}) {
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: -3em;
  }
  @media (max-width: ${sizes.S}) {
    height: 276px;
    // position: absolute;
    // bottom: -30px;
    // left: 20px;
    // width: calc(100% - 40px);
    margin-bottom: -2em;
  }
`;

export const SellContentDescriptionWrapper = styled.div`
  margin-top: 96px;
  display: flex;
  justify-content: space-between;
  max-width: ${sizes.XL};
  h2 {
    color: #263a54;
    margin-bottom: 24px;
    margin-top: 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
  }
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #334d70;

  @media (max-width: ${sizes.L}) {
    flex-direction: column;
    margin: 60px 0;
  }
`;

export const SellContentDescriptionTitle = styled.div`
  width: 60%;
  max-width: ${sizes.XL};
  div {
    margin-bottom: 24px;
  }
  @media (max-width: ${sizes.L}) {
    width: 100%;
  }
`;

export const CheckMarkItem = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: ${colors.san_juan_grey};
  max-width: ${sizes.XL};

  &:before {
    content: '';
    display: inline-block;
    min-width: 24px;
    min-height: 24px;
    background: url('/icons/checkBlue.svg') no-repeat;
    margin-right: 16px;
    background-size: contain;
  }

  @media (max-width: ${sizes.S}) {
    font-size: 17px;
  }
`;
