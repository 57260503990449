import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config';
import { dashboardActions } from '@pickles/shared/redux/actions';
import { map } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DashboardHooks } from '../../app/redux/hooks';
import { searchIcon } from '../Shared/Icons';
import { SearchBar } from './SearchBar';

interface SearchBarWithSuggestionProps {
  fullWidth?: boolean;
}

const SearchBarWithSuggestion: FC<SearchBarWithSuggestionProps> = ({ fullWidth }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const searchedTerm = router.query.search as string;

  const searchResultsRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState<string>(searchedTerm || '');
  const [searchMake, setSearchMake] = useState<string>('');
  const [isSearchResultOpen, setIsSearchResultOpen] = useState<boolean>(false);
  const [isSearchBarTouched, setIsSearchBarTouched] = useState<boolean>(false);

  const querySuggestionList = DashboardHooks.useSearchSuggestions();

  const clearSearchField = useCallback(() => {
    setSearch('');
  }, []);

  useEffect(() => {
    if (searchMake) {
      // dispatch(inventoryActionCreators.setSearchOptions({ make: searchMake }));
      // dispatch(inventoryAction.getSearchOptions());
    } else {
      clearSearchField();
    }
  }, [clearSearchField, dispatch, searchMake]);

  useEffect(() => {
    if (!search) {
      setSearchMake('');
    }
  }, [search]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    if (querySuggestionList.length && search.length) {
      setIsSearchResultOpen(true);
    } else {
      setIsSearchResultOpen(false);
    }
  }, [querySuggestionList, search.length]);

  const onClickQuerySuggestionItem = (keyword: string) => {
    router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/search?search=${keyword}`);
  };

  const setSearchBarTouchhandler = (status: boolean) => {
    setIsSearchBarTouched(status);
  };

  const changeInputHandler = (text: string) => {
    setSearch(text);
    // getSearchResult(text);
    // dispatch(inventoryActionCreators.getSearchAutocomplete(text));
    dispatch(dashboardActions.getSearchSuggestions(text));
  };

  const handleClickOutside = (event: any) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setIsSearchResultOpen(false);
    }
  };

  return (
    <>
      <SearchBar
        fullWidth={fullWidth}
        make={searchMake}
        search={search}
        placeholder="Search items & auctions"
        onChange={changeInputHandler}
        setTouched={setSearchBarTouchhandler}
        clearSearchField={clearSearchField}
      />
      {isSearchResultOpen && isSearchBarTouched ? (
        <SuggestionWrapper ref={searchResultsRef}>
          {map(querySuggestionList, (car, index) => {
            return (
              <Suggestion
                key={car + index}
                onClick={() => onClickQuerySuggestionItem(car)}
                id="search-result"
              >
                <div
                  style={{
                    marginRight: '9.5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Image src={searchIcon} alt="search" />
                </div>
                {car}
              </Suggestion>
            );
          })}
        </SuggestionWrapper>
      ) : null}
    </>
  );
};

export default SearchBarWithSuggestion;

const SuggestionWrapper = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  padding-top: 16px;
  padding-left: 17.5px;
  padding-bottom: 1px;
  border: 1px solid #D6DADF;
  border-radius: 11px;
  color: #334D70;
  font-size: 16px;
  font-family: BasierCircle;
  top: 60px;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Suggestion = styled.div`
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
