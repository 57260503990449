import React from 'react';
import { CSSProperties } from 'styled-components';
import { MainText } from './style';

interface MainTextComponentProps {
  text: string | null;
  align?: string;
  fontSize?: number;
  marginBottom?: number;
  marginTop?: number;
  fontFamily?: string;
  fontColor?: string;
  style?: CSSProperties;
  width?: CSSProperties;
  lineHeight?: CSSProperties['lineHeight'];
}

const MainTextComponent = ({ text, ...restProps }: MainTextComponentProps) => (
  <MainText id="main-text" {...restProps}>
    {text}
  </MainText>
);

export default MainTextComponent;
