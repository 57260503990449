import styled from 'styled-components';

import COLORS from '@pickles/shared/utils/colors';
import { ClickButton } from '../Shared/styles';
import sizes from '../../util/sizes';

type StyleProps = {
  open?: boolean;
  bottom?: boolean;
  bgOnScroll?: boolean;
  navbarBgColor?: string;
  isInNavbar?: boolean;
};
export const ProfileHeaderInfo = styled.div`
  display: flex;
  @media (max-width: ${sizes.S}) {
    // padding: 20px 0;
  }
`;
export const ProfileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  max-width: ${sizes.XL};
  @media (max-width: ${sizes.M}) {
    flex-flow: column;
    align-items: flex-start;
    margin: 20px 0;
  }
`;

export const AvatarContainer = styled.div`
  margin-right: 15px;
  height: 58px;
  width: 58px;
`;

export const DetailsUserName = styled.div`
  color: ${COLORS.rhino_black};
  font-size: 24px;
  font-family: BasierCircle-Medium;
  line-height: 30px;
  margin-bottom: 2px;
`;

export const DetailsEmail = styled.div`
  line-height: 20px;
`;

export const ProfileHeaderAssets = styled.div`
  background: linear-gradient(
    107.48deg,
    ${COLORS.jordy_blue} -12.3%,
    ${COLORS.blumine_main_blue} 46.24%
  );
  box-shadow: 0px 4px 16px rgba(25, 73, 135, 0.31);
  border-radius: 16px;
  margin-left: 58px;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;

  > div:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    padding-right: 57px;
    padding: 20px;
  }
  @media (max-width: ${sizes.M}) {
    flex-flow: column;
    margin: 20px 0;
    height: auto;
    white-space: nowrap;
    padding-left: 10px;
    > div {
      width: calc(100% - 40px);
    }
    > div:first-child {
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      padding: 20px 0;
    }
  }
`;

export const DetailsAvailable = styled.div`
  font-size: 23px;
  color: ${COLORS.white};
  font-weight: 600;
  font-family: BasierCircle;
  white-space: nowrap;
`;

export const DetailsHeader = styled.div`
  font-size: 13px;
  color: ${COLORS.white};
  opacity: 0.6;
  margin-top: 7px;
  line-height: 16px;
`;

export const DetailsHoldHeader = styled(DetailsHeader)`
  white-space: nowrap;
  margin-top: 7px;
  opacity: 1;
`;

export const DetailsRefund = styled(ClickButton)`
  background-color: ${COLORS.white};
  color: ${COLORS.blumine_main_blue};
  font-family: BasierCircle;

  &:hover {
    color: ${COLORS.white};
  }
`;

export const DetailsTopUp = styled(ClickButton)`
  color: ${COLORS.white};
  background-color: ${COLORS.cerulean_blue};
  font-family: BasierCircle;
  white-space: nowrap;
`;

export const DetailsActions = styled.div`
  display: flex;
  margin-left: auto;
  padding: 20px;

  @media (max-width: ${sizes.M}) {
    gap: 10px;
    padding: 20px 0;
    padding-top: 0;
    margin-left: 0;
    > button {
      width: calc(50% - 5px);
      margin: 0 !important;
      padding: 9px 15px !important;
    }
  }
  > button {
    padding: 9px 28px;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    padding: 9px 51px;
    height: 44px;
    font-weight: 600;
    border: none;
    margin: 0 7px;
    cursor: pointer;
    font-family: BasierCircle-SemiBold;
  }
`;

export const DetailsAvailableContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsAvailableHoldOn = styled(DetailsAvailable)`
  position: relative;
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;

  &:before {
    content: '';
    background-color: ${COLORS.yellow};
    height: 6px;
    width: 6px;
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 5px;
    left: -12px;
  }
`;

export const DetailsAvailableHoldOnContainer = styled.div`
  padding: 20px 38px;
  @media (max-width: ${sizes.M}) {
    padding: 20px 15px;
  }
`;

// export const ProfileHeaderInfo = styled.div`
//   display: flex;
//   align-items: center;
// `;
