import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { AvailableContainer, BlockedContainer } from '../../Navbar/ProfileMenu.style';
import { AvatarContainer } from '../../ProfileHeader/style';
import { MobileDetailsUserName, MobileProfileInfoWrapper } from '../BurgerMenu/MobileMenu/style';
import placeholderImg from '/public/images/accountImg.png';
import { Flex } from '../Layout/Flex';

import arrowRightIcon from '/public/icons/arrowRight.svg';
import { IUser } from '@pickles/shared/models';
import { numberToAmountLabel } from '@pickles/shared/utils/helpers';
import { UserHooks } from '../../../app/redux/hooks';

interface MobileProfileHeaderProps {
  account: IUser.Account;
  handleProfilePress: () => void;
}

export const MobileProfileHeaderInfo: FC<MobileProfileHeaderProps> = (props) => {
  const { account, handleProfilePress } = props;
  const token = UserHooks.useUserToken();

  return (
    <MobileProfileInfoWrapper onClick={() => handleProfilePress()}>
      <Flex flexDirection="row" alignItems="center">
        <div>
          <AvatarContainer>
            <Image
              // TODO: load the profile picture
              src={account?.profpicUrl && token ? placeholderImg : placeholderImg}
              alt="avatar"
              width={58}
              height={58}
            />
          </AvatarContainer>
        </div>
        <div>
          <MobileDetailsUserName>{account?.lastName}</MobileDetailsUserName>
          <AvailableContainer>
            <div>Available:</div> {numberToAmountLabel(account?.availableBalance)}
          </AvailableContainer>
          <BlockedContainer>
            <div>Blocked:</div> {numberToAmountLabel(account?.blockedAmount)}
          </BlockedContainer>
        </div>
      </Flex>
      <Link href="/profile">
        <>
          <Image src={arrowRightIcon} alt="Arrow icon" />
        </>
      </Link>
    </MobileProfileInfoWrapper>
  );
};
