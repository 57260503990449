import { IItem } from '../models';

// WARNING - IF WE CHANGE THIS, ALL EXISTING URLS WILL BE BROKEN!!!
const HASH_KEYS = {
  ITEMS: 'PicklesAuctionsItems',
  AUCTIONS: 'PicklesAuctions',
};

const textToChars = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));

export const hashStr = (key: string, str: string) => {
  const byteHex = (n: any) => {
    let hex = '0' + Number(n).toString(36);
    return hex.substring(hex.length - 2);
  };
  const applySaltToChar = (code: any) =>
    // eslint-disable-next-line no-bitwise
    textToChars(key).reduce((a: number, b: number) => a ^ b, code);
  return str?.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const dehashStr = (key: string, ref: any) => {
  const applySaltToChar = (code: any) =>
    // eslint-disable-next-line no-bitwise
    textToChars(key).reduce((a: number, b: number) => a ^ b, code);
  return ref
    .match(/.{1,2}/g)
    .map((hex: string) => parseInt(hex, 36))
    .map(applySaltToChar)
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
};

export const getAuctionItemRef = (item: IItem.Item) => {
  const slug = slugify(item.name);
  const saleEventId = item.saleEvent?.id;
  const itemId = item.id;
  // prettier-ignore
  const ref = [
    slug,
    hashStr(HASH_KEYS.ITEMS, itemId.toString()),
  ];
  if (saleEventId) {
    ref.push(hashStr(HASH_KEYS.AUCTIONS, saleEventId.toString()));
  }
  const refStr = ref.join('-');
  return refStr;
};

export const getIdsByAuctionItemRef = (ref: any) => {
  const refs = ref.toString().split('-');
  const auctionRef = refs[refs.length - 1];
  const itemRef = refs[refs.length - 2];
  let saleEventId: null | number = +dehashStr(HASH_KEYS.AUCTIONS, auctionRef);
  let itemId: number = +dehashStr(HASH_KEYS.ITEMS, itemRef);

  if (isNaN(saleEventId) && (isNaN(itemId) || itemId === 0)) {
    // the url only consist of itemRef
    saleEventId = null;
    itemId = +dehashStr(HASH_KEYS.ITEMS, auctionRef);
  }

  return {
    saleEventId,
    itemId,
  };
};

export const getAuctionRefByAuction = (auction: any) => {
  const slug = slugify(auction.name);
  const saleEventId = auction.id;
  const saleEventIdStr = saleEventId?.toString();
  return `${slug}-${hashStr(HASH_KEYS.AUCTIONS, saleEventIdStr)}`;
};

export const getAuctionIdByAuctionRef = (ref: any): number => {
  let auctionRef = ref.split('-');
  auctionRef = auctionRef[auctionRef.length - 1];
  return +dehashStr(HASH_KEYS.AUCTIONS, auctionRef);
};

export const slugify = (text: string, replaceWith = '') => {
  return text
    ?.toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, replaceWith ? replaceWith : '') // Remove all non-word chars and replace with custom operator
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};
