import colors from '@pickles/shared/utils/colors';
import Image from 'next/image';
import styled from 'styled-components';
import sizes from '../../../util/sizes';
import { ClickButton } from '../../Shared/styles';
import { InputField } from '../Input/style';

export const Container = styled.div`
  display: flex;
  height: 356px;
  width: 100%;
  background-color: ${colors.rhino_black};
  justify-content: space-around;
  align-items: center;
  @media (max-width: ${sizes.M}) {
    height: initial;
    width: 100%;
  }
`;

export const Main = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
  @media (max-width: ${sizes.M}) {
    padding: 56px 0;
    flex-direction: column-reverse;
    > div:not(:first-child) {
      margin-bottom: 48px;
    }
  }
`;

export const LeftBlock = styled.div`
  width: 300px;
`;

export const InfoBlock = styled.div`
  margin-bottom: 32px;
`;

export const LogoBlock = styled.div`
  width: 130px;
  height: 32px;
  position: relative;
  margin-bottom: 41px;
`;

export const Logo = styled(Image)``;

export const Info = styled.div`
  font-family: BasierCircle;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'liga' off;
  color: ${colors.mischka};
  margin-bottom: 8px;
`;

export const IconBlock = styled(ClickButton)`
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 7px;
  margin-right: 16px;
  border: 0px;

  &:hover {
    transition-duration: 0.1s;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Icon = styled(Image)``;

export const IconsPanel = styled.div`
  flex-direction: row;
  display: flex;
`;

export const CompanyBlock = styled.div``;

export const SupportBlock = styled.div``;

export const StayUpToDateBlock = styled.div`
  width: 370px;
  @media (max-width: ${sizes.M}) {
    width: 100%;
  }
`;

export const Title = styled.div`
  font-family: BasierCircle;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'liga' off;
  color: #fff;
`;

export const LinkItem = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  line-height: 26px;
  font-feature-settings: 'liga' off;
  color: ${colors.mischka};
  margin-bottom: 12px;
  cursor: pointer;
`;

export const LongText = styled.div`
  font-family: BasierCircle;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${colors.mischka};
`;

export const EmailBlock = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const EmailInput = styled(InputField)`
  background-color: ${colors.white_01};
  border: 0px;
  color: ${colors.white};
  padding: 13px 12px 14px 12px;
  width: '100%';
  height: 48px;
  flex: 1;
  font-family: BasierCircle;
`;

export const SubmitButton = styled(ClickButton)`
  outline: none;
  font-size: 16px;
  font-weight: 600;
  border: none;
  font-family: BasierCircle-SemiBold;
  border-radius: 8px;
  background-color: ${colors.cerulean_blue};
  color: #fff;
  padding: 11px 0px;
  height: 48px;
  width: 92px;
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: ${colors.cerulean_blue};
  }
  &:disabled {
    background-color: ${colors.black_009};
  }
`;
