import { IWeb } from '@pickles/shared/models';
import Link from 'next/link';
import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react';

import { ItemContainer, ArrowDown, NavItemDropdownContainer, DropdownLinkWrapper } from './style';

interface IItemProps {
  isActive: boolean;
  headerLinks?: { [key: string]: IWeb.ILink[] };
  item: {
    text: string;
    link?: string;
    strapiKey?: string;
  };
  onClick?: () => void;
}

export const NavbarItem: FC<IItemProps> = forwardRef(
  ({ onClick, isActive = false, headerLinks, item }, ref) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const isDropDown = useMemo(() => item.strapiKey && headerLinks && headerLinks[item.strapiKey], [
      item.strapiKey,
      headerLinks,
    ]);

    const setActiveDropdownHandler = useCallback(() => {
      if (!isDropDown) {
        return;
      }
      setShowDropdown((prevState) => !prevState);
    }, [isDropDown]);

    return (
      <ItemContainer
        isActive={isActive}
        onClick={onClick}
        onMouseEnter={setActiveDropdownHandler}
        onMouseLeave={setActiveDropdownHandler}
        id={`nav-item-${item.text?.toLowerCase().replace(' ', '-')}`}
      >
        {item.text}
        {isDropDown && <ArrowDown />}
        {showDropdown && item.strapiKey && headerLinks && headerLinks[item.strapiKey].length ? (
          <NavItemDropdownContainer>
            {headerLinks[item.strapiKey].map((link) => {
              return (
                <Link
                  key={link.id}
                  passHref={true}
                  href={link.basePath ? `/${link.basePath}/${link.link}` : `/${link.link}`}
                >
                  <DropdownLinkWrapper>{link.title}</DropdownLinkWrapper>
                </Link>
              );
            })}
          </NavItemDropdownContainer>
        ) : null}
      </ItemContainer>
    );
  },
);
