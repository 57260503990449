import { SUPPORT_PHONE } from '@pickles/shared/utils/config';
import _ from 'lodash';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserHooks, WebHooks } from '../../app/redux/hooks';
import { BurgerMenu } from '../Shared/BurgerMenu';
import { logo, unapprovedAccBanner } from '../Shared/Icons';
import LoginRegister from './LoginRegister';
import { MyAccount } from './MyAccount';
import { NavbarItem } from './NavbarItem';
import SearchBarWithSuggestion from './SearchBarWithSuggestion';
import {
  BannerIconWrapper,
  BannerText,
  HeaderContainer,
  Logo,
  NavContainer,
  NavItemsRow,
  NavLogoRow,
  NavMenu,
  SearchBarWrapper,
  UnApprovedAccWrapper,
} from './style';

const navBarOptions = [
  {
    text: 'Buy',
    strapiKey: 'buy',
  },
  {
    text: 'Sell',
    link: '/sell',
    strapiKey: 'sell',
  },
  {
    text: 'Services',
    link: '/services',
    strapiKey: 'services',
  },
  {
    text: 'About us',
    link: '/about-us',
    strapiKey: 'aboutUs',
  },
  {
    text: 'More',
    strapiKey: 'more',
  },
];

interface NavbarProps {
  initialActiveNavIndex: number;
  title?: string;
}

const Navbar: FC<NavbarProps> = ({ initialActiveNavIndex, title = 'Pickles Auctions' }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const isHomeRoute = router?.asPath === '/';

  const userAccount = UserHooks.useUserAccount();
  const headerLinks = WebHooks.useHeaderLinks();

  const [activeNavIndex, setActiveNavIndex] = useState(initialActiveNavIndex);

  const navBarItems = _.map(navBarOptions, (item, index) => {
    const key = `navbar-item-${item.text}-${index}`;

    return item.link ? (
      <Link href={item.link} key={key} passHref>
        <NavbarItem
          item={item}
          headerLinks={headerLinks}
          isActive={activeNavIndex === index}
          onClick={() => setActiveNavIndex(index)}
        />
      </Link>
    ) : (
      <NavbarItem
        item={item}
        headerLinks={headerLinks}
        isActive={activeNavIndex === index}
        key={key}
      />
    );
  });

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {userAccount !== null && userAccount.status === 'PENDING' && (
        <UnApprovedAccWrapper>
          <BannerIconWrapper>
            <Image src={unapprovedAccBanner} alt="Pending icon" />
          </BannerIconWrapper>
          <BannerText id={'pending_approval'}>
            {t('errors:still_pending_approval', { phone: SUPPORT_PHONE })}
          </BannerText>
        </UnApprovedAccWrapper>
      )}
      <HeaderContainer isHeaderUnderLine={!isHomeRoute} id="header-container">
        <NavContainer>
          <NavLogoRow>
            <Link href={'/'} passHref>
              <Logo>
                <Image src={logo} alt="logo" />
              </Logo>
            </Link>
            <BurgerMenu navBarOptions={navBarOptions} headerLinks={headerLinks}>Menu</BurgerMenu>
          </NavLogoRow>
          <NavItemsRow>
            <NavMenu>{navBarItems}</NavMenu>

            <SearchBarWrapper id="search-bar-wrapper">
              <SearchBarWithSuggestion />
            </SearchBarWrapper>

            {userAccount ? <MyAccount user={userAccount} /> : <LoginRegister />}
          </NavItemsRow>
        </NavContainer>
      </HeaderContainer>
    </>
  );
};

export default Navbar;
