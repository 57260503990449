import styled, { css } from 'styled-components';
import Image from 'next/image';

import colors from '@pickles/shared/utils/colors';
import { IconColors } from './SearchBar';
import { SlidingRight } from '../../styles/animations';
import sizes from '../../util/sizes';

type StyleProps = {
  open?: boolean;
  bottom?: boolean;
  bgOnScroll?: boolean;
  navbarBgColor?: string;
  isInNavbar?: boolean;
};

// type RightSideProps = {
//   isClosed: boolean;
//   isEmpty?: boolean;
// };
const logoWidth = '114px';

type RightSideProps = {
  isClosed: boolean;
};

export const ArrowDown = styled.div`
  margin-left: 9px;
  border-right: 2px solid ${colors.rhino_black};
  border-bottom: 2px solid ${colors.rhino_black};
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  display: inline-block;
`;
interface IHeaderContainer {
  isHeaderUnderLine?: boolean;
}

export const HeaderContainer = styled.div<IHeaderContainer>`
  border-bottom: ${({ isHeaderUnderLine }) => isHeaderUnderLine && `1px solid ${colors.mischka}`};
  padding: 0 135px;

  @media (max-width: ${sizes.XL}) {
    padding: 0 60px;
  }

  @media (min-width: ${sizes.XL}) and (max-width: ${sizes.XXL}) {
    padding: 0 50px;
  }

  @media (max-width: ${sizes.M}) {
    padding: 20px 50px;
    margin: 0;
    width: 100%;
    // margin-bottom: 20px;
    border-bottom: 0;
  }

  @media (max-width: ${sizes.S}) {
    padding: 20px;
  }
`;

export const NavContainer = styled.div`
  position: relative;
  font-family: BasierCircle;
  display: flex;
  font-size: 16px;
  color: ${colors.rhino_black};
  align-items: center;
  height: 84px;
  max-width: ${sizes.XL};
  margin: 0 auto;
  @media (max-width: ${sizes.M}) {
    flex-direction: column;
    height: auto;
  }
`;

export const NavLogoRow = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${sizes.M}) {
    justify-content: space-between;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }
`;

export const NavItemsRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${sizes.XXL}) {
    max-width: calc(100% - 25px);
  }
  @media (max-width: ${sizes.XL}) {
    justify-content: start;
  }
  @media (max-width: ${sizes.M}) {
    div {
      margin-left: 0;
      width: 100%;
      white-space: nowrap;
      input {
        width: 100%;
      }
    }
  }
`;

export const Logo = styled.div`
  margin-right: 30px;
  height: fit-content;
  cursor: pointer;
  img {
    min-width: ${logoWidth};
  }
`;

export const NavMenu = styled.div`
  display: flex;
  height: 100%;
  margin-left: 30px;
  @media (max-width: ${sizes.XL}) {
    margin-left: 0px;
  }
  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;

type ItemContainerProps = {
  isActive?: boolean;
};

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  cursor: pointer;
  align-items: start;
  position: relative;
  white-space: nowrap;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${colors.blumine_main_blue}` : '2px solid transparent'};
  animation: ${({ isActive }) => (isActive ? SlidingRight : 'none')};
  transition: 0.3s all ease-in-out;
  padding: 0 14px;
  font-family: BasierCircle;
  display: flex;
  align-items: center;
  padding-top: 3px;
`;

type SearchContainerProps = {
  fullWidth?: boolean;
  iconColor: IconColors;
  isMinified?: boolean;
};

export const SearchContainer = styled.input<SearchContainerProps>`
  display: flex;
  align-items: center;
  padding: 15px;
  // margin-left: auto;
  padding-left: 45px;
  border: 1px solid ${colors.mischka};
  border-radius: 8px;
  color: ${colors.san_juan_grey};
  background-image: ${({ iconColor }) =>
    iconColor === IconColors.Blue
      ? 'url(/images/search-default.png)'
      : ' url(/images/search-grey.png)'};
  background-repeat: no-repeat;
  background-position: 15px;
  outline: 0;
  min-width: 300px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : ' initial')};
  font-size: 16px;
  font-weight: 400;
  font-family: 'BasierCircle-Medium';
  height: 52px;

  &:focus {
    border: 1.2px solid ${colors.san_juan_grey};
    box-shadow: 0px 0px 10px rgba(17, 46, 84, 0.16);
  }

  &::-webkit-input-placeholder {
    font-family: 'BasierCircle';
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    background: url('/icons/searchClear.svg') no-repeat 50% 50%;
  }

  @media (max-width: ${sizes.M}) {
    ${({ isMinified, fullWidth }) =>
      css`
        width: ${isMinified ? '200px' : fullWidth ? '100% ' : '300px'};
        border: ${isMinified ? 'none' : '1px solid' + colors.mischka};
      `};
  }
`;

export const SearchImg = styled.img`
  margin-right: 15px;
`;

export const AccountText = styled.div`
  padding: 0 10px;
  white-space: nowrap;
`;

export const AccountImg = styled(Image)``;

export const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-left: 15px;
  color: ${colors.rhino_black};
  font-size: 16px;
  cursor: pointer;
  @media (max-width: ${sizes.XL}) {
    margin-left: auto;
  }
  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.rhino_black};
`;

export const LoginRegisterWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: :; */
  padding: 15px;
  margin-left: 15px;
  color: ${colors.rhino_black};
  font-size: 16px;
  cursor: pointer;
  @media (max-width: ${sizes.XL}) {
    margin-left: auto;
  }
  @media (max-width: ${sizes.M}) {
    display: none;
  }
`;
export const SearchBarWrapper = styled.div`
  margin-left: auto;
  position: relative;
  background-color: white;
  z-index: 2;
  width: 300px;
  flex-basis: 20%;

  @media (max-width: ${sizes.XL}) {
    // margin-left: 15px;
    display: none;
  }
  @media (min-width: ${sizes.M}) and (max-width: ${sizes.XL}) {
    display: none;
  }
  > div:last-child {
    > div {
      width: calc(100% - 20px);
    }
  }
`;

export const NavItemDropdownContainer = styled.div`
  max-width: 336px;
  position: absolute;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${colors.white};
  border-radius: 6px;
  border: 1px solid ${colors.mischka};
  top: 70px;
  left: 50%;
  min-width: 250px;
  z-index: 10;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, 0);

  &:before,
  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    align-self: center;
    border-style: solid;
  }

  &:before {
    border-color: transparent transparent ${colors.mischka} transparent;
    border-width: 0 10px 10px 10px;
    top: -10px;
  }

  &:after {
    border-color: transparent transparent ${colors.white} transparent;
    border-width: 0 9px 9px 9px;
    top: -8px;
  }
`;

export const DropdownLinkWrapper = styled.div`
  margin: 1px 0;
  padding: 10px 12px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${colors.zircon_light_blue};
    border-radius: 6px;
  }
`;

export const UnApprovedAccWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.blumine_main_blue};
  color: ${colors.white};
  font-family: BasierCircle;
  padding: 12px 20px;

  @media (max-width: ${sizes.S}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const BannerIconWrapper = styled.div`
  margin-right: 8px;
`;

export const AnnouncementWrapper = styled(UnApprovedAccWrapper)`
  background: ${colors.rhino_black};
`;

export const BannerText = styled.span`
  font-size: 16px;
  line-height: 20px;
`;

export const TypicallyText = styled.span`
  font-size: 16px;
`;
