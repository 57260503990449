import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config';
import { useRouter } from 'next/router';
import { ChangeEvent, FC } from 'react';
import { SearchContainer } from './style';
interface IItemProps {
  placeholder: string;
  fullWidth?: boolean;
  isEmpty?: boolean;
  onChange?: (string: string) => void;
  iconColor?: IconColors;
  make?: string;
  search?: string;
  setTouched?: (status: boolean) => void;
  clearSearchField?: () => void;
  isMinified?: boolean;
}

export enum IconColors {
  Grey = 'Grey',
  Blue = 'Blue',
}

export const SearchBar: FC<IItemProps> = ({
  placeholder,
  fullWidth,
  onChange,
  iconColor = IconColors.Blue,
  // make,
  search,
  setTouched,
  // clearSearchField,
  isMinified = false,
}) => {
  // const dispatch = useDispatch();
  const router = useRouter();

  // const [searchedTerm, setSearchTerm] = useState<string>(search || '');

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(event.currentTarget.value);
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  // const qwe = useCallback((event: any) => {
  //   if(event.charcode === 13) {
  //     dispatch(inventoryActionCreators.setSearchOptions({make: make}));
  //     dispatch(inventoryActionCreators.clearSearchOptions());
  //     clearSearchField();
  //     if(!router.asPath.includes('/auction/search')) {
  //       router.push(`/auction/search?make=${make}`);
  //     } else {
  //       router.push(`/auction/search?make=${make}`, undefined, {shallow: true});
  //     }
  //   }
  // }, [dispatch])

  return (
    <SearchContainer
      fullWidth={fullWidth}
      type="search"
      placeholder={placeholder}
      onChange={changeHandler}
      iconColor={iconColor}
      id="search-input"
      isMinified={isMinified}
      value={search}
      onKeyPress={
        // qwe
        (event: any) => {
          if (event.charCode === 13) {
            // dispatch(inventoryActionCreators.setSearchOptions({ make }));
            // dispatch(inventoryActionCreators.clearSearchOptions());
            // clearSearchField();
            setTouched && setTouched(false);
            if (!router.asPath.includes(`/${WEBAPP_AUCTION_ROUTE_PATH}/search`)) {
              router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/search?search=${search}`);
            } else {
              router.push(`/${WEBAPP_AUCTION_ROUTE_PATH}/search?search=${search}`, '', {
                shallow: true,
              });
            }
          }
        }
      }
      onFocus={() => setTouched && setTouched(true)}
    />
  );
};
