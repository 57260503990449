import colors from '@pickles/shared/utils/colors';
import { useTranslation } from 'react-i18next';
import { AppHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import { AnnouncementWrapper, BannerIconWrapper, BannerText } from '../Navbar/style';
import Container from '../Shared/Container';
import { ExclamationIcon } from '../Shared/Icons';
import Typography from '../Shared/Typography';

const Banner = () => {
  const appConfig = AppHooks.useMobileAppConfig();
  const { isTablet } = useScreenSize();
  const { t } = useTranslation();

  return (
    <AnnouncementWrapper>
      <BannerIconWrapper>
        <ExclamationIcon fillColor={colors.white} />
      </BannerIconWrapper>
      <Container flex={isTablet ? 'column' : 'row'} width="initial" alignItems="flex-start">
        {isTablet && (
          <Typography variant="h6" color="white">
            {t('titles:notice')}
          </Typography>
        )}
        <BannerText>{appConfig?.dashboardNotice}</BannerText>
      </Container>
    </AnnouncementWrapper>
  );
};

export default Banner;
